import gql from 'graphql-tag';

export const GOKER = gql`
  query Goker($id: Int!) {
    user(id: $id) {
      ok
      user {
        name 
        nickname
        email
        role
        telephone
        githubUser
        admission
        bio
        avatar
      }
      errors {
        message
      }
    }
  }
`;