import gql from 'graphql-tag';

export const NEW_GOKER = gql`
  mutation NewGoker($data: UserInput!) {
    userAdd(data: $data) {
      ok 
      errors {
        message
      }
    }
  }
`;