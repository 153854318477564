import Login from "views/Login";
import Dashboard from "views/Dashboard";
import Gokers from "views/Gokers";
import GokerProfile from "views/GokerProfile";
import GokerRegister from "views/GokerRegister";
import Vacations from "views/Vacations";
import VacationRegister from "views/VacationRegister";

const routes = [
  {
    path: "/login",
    name: "",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/gokers",
    name: "Gokers",
    icon: "tim-icons icon-single-02",
    component: Gokers,
    layout: "/admin"
  },
  {
    path: "/novo-goker",
    name: "",
    icon: "",
    component: GokerRegister,
    layout: "/admin"
  },
  {
    path: "/perfil-goker/:id",
    name: "",
    icon: "",
    component: GokerProfile,
    layout: "/admin"
  },
  {
    path: "/ferias",
    name: "Férias",
    icon: "tim-icons icon-calendar-60",
    component: Vacations,
    layout: "/admin"
  },
  {
    path: "/registrar-ferias",
    name: "",
    icon: "",
    component: VacationRegister,
    layout: "/admin"
  }
];

export default routes;
