import React from "react";
import Loader from "react-loader-spinner"

import "./styles.scss";

function Loading({ title, type, width, height }) {
  return (
    <div className="container-loading">
      <Loader type={type} color="#3ED206" height={height} width={width} />
      <span>{title}</span>
    </div>
  )
}

export default Loading
