import React, { useState, useEffect } from "react";
import createPersistedState from 'use-persisted-state';
import { Link, useHistory } from "react-router-dom";
import { cellphoneMask } from "utils/functions";
import { showError, showSuccess } from "utils/toast";

// GraphQL
import { useMutation } from "@apollo/react-hooks";
import { NEW_GOKER } from "./goker.graphql";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";

// components
import { Loading } from "components";

const rolesState = createPersistedState('roles');

function GokerRegister(props) {
  const history = useHistory();

  // React States
  const [roles, setRoles] = rolesState([]);
  const [state, setState] = useState({
    type: "Goker"
  });

  // GraphQL Mutation's 
  const [newGoker, { data: ng, loading }] = useMutation(NEW_GOKER);

  const handleRegister = async () => {
    const { name, email, admission, role, password } = state;

    if (name && email && password && role && admission) {
      await newGoker({
        variables: {
          data: state
        }
      });
    } else {
      showError("Informe todos os dados obrigatórios para continuar!");
    }
  }

  useEffect(() => {
    if (ng) {
      const { ok, errors } = ng.userAdd;

      if (!ok) {
        if (errors && errors.length) {
          showError(errors[0].message);
        }
      } else {
        showSuccess("Goker cadastrado com sucesso!");
        setState({ type: "Goker" });
        history.goBack();
      }
    }
  }, [ng])

  return (
    <>
      {loading && <Loading type="BallTriangle" title="Cadastrando Goker..." />}
      <div className="content">
        <Row>
          <Col md="8">
            <Breadcrumb>
              <BreadcrumbItem><Link to="/admin/dashboard">Home</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to="/admin/gokers">Gokers</Link></BreadcrumbItem>
              <BreadcrumbItem active>Novo Goker</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Cadastrar novo Goker</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Nome Completo *</label>
                        <Input
                          placeholder="Nome Completo"
                          type="text"
                          onChange={(e) => setState({ ...state, name: e.target.value })}
                          value={state?.name}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Como o Goker gosta de ser chamado?</label>
                        <Input
                          placeholder="Apelido"
                          type="text"
                          onChange={(e) => setState({ ...state, nickname: e.target.value })}
                          value={state?.nickname}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Telefone</label>
                        <Input
                          placeholder="Número do Telefone"
                          type="text"
                          maxLength={15}
                          onChange={(e) => setState({ ...state, telephone: e.target.value })}
                          value={cellphoneMask(state?.telephone || "")}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Github</label>
                        <Input
                          placeholder="Usuário do Github"
                          type="text"
                          onChange={(e) => setState({ ...state, githubUser: e.target.value })}
                          value={state?.githubUser}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Admissão *</label>
                        <Input
                          placeholder="Ex: 01/01/2020"
                          type="date"
                          maxLength={10}
                          onChange={(e) => setState({ ...state, admission: e.target.value })}
                          value={state?.admission}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Cargo *</label>
                        <Input type="select" onChange={(e) => setState({ ...state, role: e.target.value })}>
                          <option selected disabled>Selecione...</option>
                          {roles.map((item, key) => (
                            <option value={item.name} key={key}>{item.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Email *</label>
                        <Input
                          placeholder="Email"
                          type="email"
                          onChange={(e) => setState({ ...state, email: e.target.value })}
                          value={state?.email}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Senha *</label>
                        <Input
                          placeholder="Senha Inicial do Goker"
                          type="password"
                          onChange={(e) => setState({ ...state, password: e.target.value })}
                          value={state?.password}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="button"
                  onClick={() => handleRegister()}
                >
                  Cadastrar
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default GokerRegister;