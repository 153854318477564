import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Cookies from "js-cookie";

// Visual Components
import { Container, Row } from 'reactstrap';

// Routes
import routes from "routes";

function Auth(props) {
  const history = useHistory();

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      history.push("/admin/dashboard");
    }
  }, [])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        className="bg-gradient-info"
      >
        <Container>
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Auth;
