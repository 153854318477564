import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { showError } from "utils/toast";

// GraphQL
import { useQuery } from "@apollo/react-hooks";
import { GOKER } from "./goker.graphql";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

// Components
import { Loading } from "components";

// Images 
import kImg from "assets/img/avatar_default.png";

function GokerProfile(props) {
  const history = useHistory();
  const { id } = useParams();

  // React States
  const [user, setUser] = useState({});

  // GraphQL Queries
  const { data, loading } = useQuery(GOKER, { variables: { id: Number(id) } });

  useEffect(() => {
    if (data) {
      console.log(data);
      const { ok, errors, user } = data.user;

      if (!ok) {
        if (errors && errors.length) {
          showError(errors[0].message);
          history.goBack();
        }
      } else {
        setUser(user);
      }
    }
  }, [data])

  return (
    <>
      {loading && <Loading type="BallTriangle" title="Carregando..." />}
      <div className="content">
        <Row>
          <Col md="12">
            <Breadcrumb>
              <BreadcrumbItem><Link to="/admin/dashboard">Home</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to="/admin/gokers">Gokers</Link></BreadcrumbItem>
              <BreadcrumbItem active>Perfil do Goker</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Dados do Goker</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Nome Completo</label>
                        <Input
                          placeholder="Nome Completo"
                          type="text"
                          value={user?.name || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Apelido</label>
                        <Input
                          placeholder="Como o goker gosta de ser chamado?"
                          type="text"
                          value={user?.nickname || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          placeholder="Email"
                          type="email"
                          value={user?.email || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Telefone</label>
                        <Input
                          placeholder="Número do Telefone"
                          type="text"
                          value={user?.telephone || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Github</label>
                        <Input
                          placeholder="Usuário do Github"
                          type="text"
                          value={user?.githubUser || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Admissão</label>
                        <Input
                          disabled
                          placeholder="Ex: 01/01/2020"
                          type="text"
                          maxLength={10}
                          value={format(new Date(Number(user?.admission || 0)), "dd/MM/yyyy") || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>Biografia</label>
                        <Input
                          cols="80"
                          placeholder="Bio do Goker"
                          rows="4"
                          type="textarea"
                          value={user?.bio || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="button">
                  Salvar
                </Button>
                <Button className="btn-fill" color="info" type="button">
                  Resetar Senha
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img
                      alt="Julio Augusto Morais de Sousa"
                      className="avatar"
                      src={user?.avatar || kImg}
                    />
                    <h5 className="title">{user?.nickname || user?.name || ""}</h5>
                  </a>
                  <p className="description">{user?.role || ""}</p>
                </div>
                <div className="card-description text-center">
                  {user?.bio || ""}
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-linkedin" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default GokerProfile;
