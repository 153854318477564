import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastContainer } from 'react-toastify';

import client from "services/apolloClient";

import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import 'react-toastify/dist/ReactToastify.css';

const hist = createBrowserHistory();

ReactDOM.render(
  <ApolloProvider client={client}>
    <ToastContainer />
    <Router history={hist}>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);
