import { toast } from 'react-toastify'

export const showSuccess = (message, autoClose = 5000) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose,
  })
}

export const showError = (message, autoClose = 5000) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose,
  })
}

export const showWarning = (message, autoClose = 5000) => {
  toast.warn(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose,
  })
}
