import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { showError, showSuccess } from "utils/toast";

// GraphQL
import { useMutation } from "@apollo/react-hooks";
import { AUTH_EXTERN, AUTH_USER } from "./login.graphql";
import { setExternToken, setToken, setUserData } from "services/apolloClient";

import "./styles.scss";

// Visual Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  InputGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Input,
  Col,
} from "reactstrap";

// Components
import { Loading } from "components";

// Images
import logoImg from "assets/img/logo/logo@3x.png";
import kImg from "assets/img/k.png";
import kPassImg from "assets/img/k-pass.png";

const actualToken = Cookies.get("externToken");

function Login() {
  const history = useHistory();

  // React States
  const [state, setState] = useState({
    email: '',
    password: '',
    focusPass: false
  });

  // GraphQL Mutations
  const [authExtern, { data: dataToken }] = useMutation(AUTH_EXTERN);
  const [authUser, { data, loading }] = useMutation(AUTH_USER);

  useEffect(() => {
    if (!actualToken) {
      authExtern({
        variables: {
          apiAccess: process.env.REACT_APP_API_ACCESS,
          apiKey: process.env.REACT_APP_API_KEY
        }
      });
    }
  }, []);

  if (dataToken) {
    const { ok, token } = dataToken.externClient;

    if (ok) {
      setExternToken(token);
    }
  }

  const handleLogin = async () => {
    if (state.email && state.password) {
      await authUser({
        variables: {
          email: state.email,
          password: state.password
        }
      });
    } else {
      showError("Informe seu email e senha para continuar!");
    }
  }

  useEffect(() => {
    if (data) {
      const { ok, token, errors, user } = data.userLogin;

      if (ok) {
        if (!user.active) {
          showError("Usuário desabilitado, entre em contato com os administradores do sistema!");
        }

        if (user.type !== "Admin" && user.type !== "RH" && user.type !== "Diretoria") {
          showError("Acesso não autorizado, entre em contato com os administradores do sistema!");
        } else {
          history.push("/admin/dashboard");
          setToken(token);
          setUserData(user);
          showSuccess("Login realizado com sucesso!");
        }
      } else {
        if (errors && errors.length) {
          showError(errors[0].message);
        }
      }
    }
  }, [data]);

  console.log(state);

  return (
    <>
      {loading && <Loading type="BallTriangle" title="Carregando..." />}
      <Col lg="5" md="7">
        <div className="container-k">
          <img src={state.focusPass ? kPassImg : kImg} />
        </div>
        <Card className="shadow border-0">
          <CardHeader
            className="mt-3"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CardImg src={logoImg} style={{ width: 80, height: 80 }} />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-focus">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => setState({ ...state, email: e.target.value })}
                    value={state?.email}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-focus">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-lock-circle" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Senha"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setState({ ...state, password: e.target.value })}
                    value={state?.password}
                    onFocus={() => setState({ ...state, focusPass: true })}
                    onBlur={() => setState({ ...state, focusPass: false })}
                  />
                </InputGroup>
              </FormGroup>
              <Button
                color="primary"
                className="animation-on-hover"
                style={{ width: '100%' }}
                type="button"
                onClick={() => handleLogin()}
              >
                Acessar
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default Login;