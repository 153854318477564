import gql from 'graphql-tag';

export const ROLES = gql`
  query {
    roles {
      ok 
      roles {
        id
        name
      }
      errors {
        message
      }
    }
  }
`;