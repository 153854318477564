/*eslint-disable*/
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { PropTypes } from "prop-types";

import PerfectScrollbar from "perfect-scrollbar";

import { Nav } from "reactstrap";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  render() {
    const { bgColor, routes, logo } = this.props;

    let logoImg = null;
    let logoText = null;

    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo.imgSrc} alt="go-on-logo" style={{ width: 70, height: 70 }} />
            </div>
          </a>
        );
      } else {
        logoImg = (
          <Link
            to={logo.innerLink}
            className="simple-text"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo.imgSrc} alt="go-on-logo" style={{ width: 70, height: 70 }} />
            </div>
          </Link>
        );
        logoText = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-normal"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </Link>
        );
      }
    }

    return (
      <div className="sidebar" data={bgColor}>
        <div className="sidebar-wrapper" ref="sidebar">
          {logoImg !== null && (
            <div className="logo">
              {logoImg}
            </div>
          )}
          <Nav>
            {routes.filter((value) => value.name)
              .map((prop, key) => {
                if (prop.redirect) return null;

                return (
                  <li
                    className={
                      this.activeRoute(prop.path) +
                      (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={this.props.toggleSidebar}
                    >
                      <i className={prop.icon} style={{ color: 'black' }} />
                      <p style={{ color: 'black' }}>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              })}
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string
  })
};

export default Sidebar;
