import { concat } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import Cookies from "js-cookie";

let authToken = null;

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_URL_API,
});

/**
 * @description Cria o middleware para adicionar o token nas requisições
 */
const authMiddleware = setContext((_, { headers }) => {
  const externToken = Cookies.get("externToken");
  const token = Cookies.get("token");

  return {
    headers: {
      authorization: token ? token : externToken,
    }
  };
});

/**
 * @description Recebe o token de cliente externo e salva nos cookies
 * @param {string} token 
 */
export const setExternToken = async (token) => {
  if (token) {
    Cookies.set("externToken", token, {
      expires: 30
    });
  }
}

/**
 * @description Recebe o token do usuario e salva nos cookies
 * @param {string} token 
 */
export const setToken = async (token) => {
  if (token) {
    Cookies.set("token", token, {
      expires: 2
    });
  }
}

export const setUserData = async (user) => {
  const userString = JSON.stringify(user);

  Cookies.set("userData", userString, {
    expires: 2
  });
}

/**
 * @description Recebe o token do usuario e seta nas requisições
 * @param {string} token 
 */
export const setTokenInRequest = async (token) => {
  try {
    authToken = token ? token : null;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Destroi os tokens
 */
export const destroyToken = async () => {
  Cookies.remove("token");
  authToken = null;
}

/**
 * @description Cria o cliente apollo para efetuar chamadas na Api GraphQL
 */
const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default client;