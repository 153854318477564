import gql from 'graphql-tag';

export const AUTH_EXTERN = gql`
  mutation AuthExtern($apiAccess: String!, $apiKey: String!) {
    externClient(
      apiAccess: $apiAccess,
      apiKey: $apiKey
    ) {
      ok
      token
      errors {
        message
      }
    }
  }
`;

export const AUTH_USER = gql`
  mutation AuthUser($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      ok 
      token
      user {
        id 
        name
        email
        avatar
        role 
        active
        type
      }
      errors {
        message
      }
    }
  }
`;