import React, { useState, useEffect } from "react";
import createPersistedState from 'use-persisted-state';
import { Link, useHistory } from "react-router-dom";
import { cellphoneMask } from "utils/functions";
import { showError, showSuccess } from "utils/toast";

// GraphQL
import { useMutation } from "@apollo/react-hooks";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";

// components
import { Loading } from "components";

const rolesState = createPersistedState('roles');

function VacationRegister(props) {
  const history = useHistory();

  // React States
  const [roles, setRoles] = rolesState([]);
  const [state, setState] = useState({
    type: "Goker"
  });


  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Breadcrumb>
              <BreadcrumbItem><Link to="/admin/dashboard">Home</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to="/admin/ferias">Férias</Link></BreadcrumbItem>
              <BreadcrumbItem active>Registrar Férias</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Registrar férias de um Goker</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Nome Completo *</label>
                        <Input
                          placeholder="Nome Completo"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Como o Goker gosta de ser chamado?</label>
                        <Input
                          placeholder="Apelido"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Telefone</label>
                        <Input
                          placeholder="Número do Telefone"
                          type="text"
                          maxLength={15}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Github</label>
                        <Input
                          placeholder="Usuário do Github"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Admissão *</label>
                        <Input
                          placeholder="Ex: 01/01/2020"
                          type="date"
                          maxLength={10}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Cargo *</label>
                        <Input type="select" onChange={(e) => setState({ ...state, role: e.target.value })}>
                          <option selected disabled>Selecione...</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Email *</label>
                        <Input
                          placeholder="Email"
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Senha *</label>
                        <Input
                          placeholder="Senha Inicial do Goker"
                          type="password"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="button"
                // onClick={() => handleRegister()}
                >
                  Cadastrar
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default VacationRegister;