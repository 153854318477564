import React from "react";
import { useHistory, Link } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Form,
  Input,
  UncontrolledTooltip,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";

function Vacations(props) {
  const history = useHistory();

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Breadcrumb>
              <BreadcrumbItem><Link to="/admin/dashboard">Home</Link></BreadcrumbItem>
              <BreadcrumbItem active>Férias</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Filtros</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pl-md-4" md="4">
                      <FormGroup>
                        <label>Status</label><br />
                        <FormGroup check inline className="form-check-radio">
                          <Label className="form-check-label">
                            <Input type="radio" name="status" value="Pendente" defaultChecked />
                            Pendente
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup check inline className="form-check-radio">
                          <Label className="form-check-label">
                            <Input type="radio" name="status" value="Aprovado" />
                            Aprovado
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup check inline className="form-check-radio">
                          <Label className="form-check-label">
                            <Input type="radio" name="status" value="Negado" />
                            Negado
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup check inline className="form-check-radio">
                          <Label className="form-check-label">
                            <Input type="radio" name="status" value="Todos" />
                            Todos
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="3">
                      <FormGroup>
                        <label>Goker</label>
                        <Input
                          placeholder="Nome do Goker"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="2">
                      <FormGroup>
                        <label>Data Inicial</label>
                        <Input type="date" name="Data Inicial" />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="2">
                      <FormGroup>
                        <label>Data Final</label>
                        <Input type="date" name="Data Final" />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Pedidos de Férias</CardTitle>
                <div className="new-goker-button-area">
                  <Button color="primary" onClick={() => history.push("/admin/registrar-ferias")}>
                    <i className="fas fa-plus" style={{ paddingRight: 10 }} />
                    Cadastrar Férias
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Nome</th>
                      <th>Cargo</th>
                      <th>Data Inicio</th>
                      <th>Data Termino</th>
                      <th className="text-center">Status</th>
                      <th className="text-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Julio Augusto</td>
                      <td>Mobile Developer</td>
                      <td>10/11/2020</td>
                      <td>20/11/2020</td>
                      <td className="text-center">Aprovação<br />Pendente</td>
                      <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button
                          color="success"
                          className="animation-on-hover btn-icon btn-simple"
                          title=""
                          id="view_button_vacation_accept"
                        >
                          <i className="fas fa-check" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="view_button_vacation_accept"
                          placement="top"
                        >
                          Aprovar Pedido
                        </UncontrolledTooltip>
                        <Button
                          color="danger"
                          className="animation-on-hover btn-icon btn-simple ml-2"
                          title=""
                          id="view_button_vacation_cancel"
                        >
                          <i className="fas fa-times" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="view_button_vacation_cancel"
                          placement="top"
                        >
                          Negar Pedido
                        </UncontrolledTooltip>
                        <Button
                          color="info"
                          className="animation-on-hover btn-icon btn-simple ml-2"
                          title=""
                          id="view_button_vacation"
                          onClick={() => history.push(`/admin/perfil-goker/1`)}
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="view_button_vacation"
                          placement="top"
                        >
                          Visualizar
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Vacations;
