/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer(props) {
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            <NavLink href="https://gok.digital">Gok Digital</NavLink>
          </NavItem>
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2 text-primary" /> by{" "}
          <Link to="/">
            Gok Innovation Team.
            </Link>{" "}
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
