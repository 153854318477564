import gql from 'graphql-tag';

export const GOKERS = gql`
  query AllUsers($filter: UserFilterInput, $sort: UserSortInput, $page: Int, $limit: Int) {
    users(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      ok
      nodes {
        id
        name 
        role
        telephone
        admission
        active
      }
      totalCount
      pageInfo {
        currentPage
        itemCount
        hasNextPage
        hasPreviousPage
        pageCount
        perPage
        totalCount
      }
      errors {
        message
      }
    }
  }
`;

export const CHANGE_STATUS = gql`
  mutation ChangeStatus($id: Int!, $active: Boolean!) {
    userChangeActive(id: $id, active: $active) {
      ok 
      errors {
        message
      }
    }
  }
`;