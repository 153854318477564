import React, { useState, useEffect } from "react";
import createPersistedState from 'use-persisted-state';
import { useHistory, Link } from "react-router-dom";
import { showError, showSuccess } from "utils/toast";
import { format } from "date-fns";
import { capitalize } from "utils/functions";
import "./styles.scss";

// GraphQL
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GOKERS, CHANGE_STATUS } from "./goker.graphql";

// reactstrap components
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Table,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

// components
import { Loading } from "components";

const usersState = createPersistedState('users');
const limitState = createPersistedState('usersLimit');
const rolesState = createPersistedState('roles');

function Gokers(props) {
  const history = useHistory();

  // React States
  const [users, setUsers] = usersState([]);
  const [roles, setRoles] = rolesState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [sort, setSort] = useState({ field: "id", order: "ASC" });
  const [limit, setLimit] = limitState(5);
  const [filters, setFilters] = useState({
    name: "",
    active: true,
    role: ""
  });

  // GraphQL Queries
  const { data, loading, fetchMore, refetch } = useQuery(GOKERS, {
    variables: {
      filter: filters,
      sort,
      limit
    },
    fetchPolicy: "cache-and-network"
  });

  // GraphQL Mutation
  const [changeStatus, { data: changeData, loading: changeLoading }] = useMutation(CHANGE_STATUS);

  useEffect(() => {
    if (data) {
      const { nodes, ok, pageInfo, errors } = data.users;

      console.log("RETURN: ", nodes);

      if (!ok) {
        if (errors && errors.length) {
          showError(errors[0].message);
        }
      } else {
        setUsers(nodes);
        setPageInfo(pageInfo);
      }
    }
  }, [data]);

  const updateItems = () => {
    setLimit(limit + limit);
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        limit: limit + limit
      },
      updateQuery: (prev, { fetchMoreResult, ...rest }) => {
        if (!fetchMoreResult) return prev;

        updateItems();

        return {
          ...fetchMoreResult,
          users: {
            ...fetchMoreResult.users,
            nodes: [
              ...prev.users.nodes,
              ...fetchMoreResult.users.nodes
            ]
          }
        }
      }
    });
  }

  const handleActiveUser = async (id, status) => {
    await changeStatus({
      variables: {
        id,
        active: !status
      }
    });

    refetch();
  }

  useEffect(() => {
    if (changeData) {
      const { ok, errors } = changeData.userChangeActive;

      if (!ok) {
        if (errors && errors.length) {
          showError(errors[0].message);
        }
      } else {
        showSuccess("Alteração realizada com sucesso!");
      }
    }
  }, [changeData])

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Breadcrumb>
              <BreadcrumbItem><Link to="/admin/dashboard">Home</Link></BreadcrumbItem>
              <BreadcrumbItem active>Gokers</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Filtros</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pl-md-4" md="2">
                      <FormGroup>
                        <label>Status</label><br />
                        <FormGroup check inline className="form-check-radio">
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="status"
                              value={true}
                              onChange={() => setFilters({ ...filters, active: true })}
                              defaultChecked
                            />
                            Ativo
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup check inline className="form-check-radio">
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="status"
                              value={false}
                              onChange={() => setFilters({ ...filters, active: false })}
                            />
                            Inativo
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="3">
                      <FormGroup>
                        <label>Goker</label>
                        <Input
                          placeholder="Nome do Goker"
                          type="text"
                          onChange={(e) => setFilters({ ...filters, name: capitalize(e.target.value) })}
                          value={filters.name}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="3">
                      <FormGroup>
                        <label>Cargo</label>
                        <Input
                          type="select"
                          name="role"
                          onChange={(e) => setFilters({ ...filters, role: e.target.value })}
                        >
                          <option selected disabled>Selecione</option>
                          <option value="todos">Todos</option>
                          {roles.map((item, key) => (
                            <option value={item.name} key={key}>{item.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="2">
                      <FormGroup>
                        <label>Quantidade de Itens</label>
                        <Input
                          type="select"
                          name="qtdItems"
                          onChange={(e) => setLimit(Number(e.target.value))}
                          value={limit}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Todos os Gokers</CardTitle>
                <div className="new-goker-button-area">
                  <Button color="primary" onClick={() => history.push("/admin/novo-goker")}>
                    <i className="fas fa-plus" style={{ paddingRight: 10 }} />
                    Novo Goker
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {users.length === 0 ? (
                  <Alert color="primary text-center">
                    Nenhum Goker encontrado!
                  </Alert>
                ) : (
                    <Table className="tablesorter" responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>Nome</th>
                          <th>Cargo</th>
                          <th>Telefone</th>
                          <th>Admissão</th>
                          <th className="text-center">Status</th>
                          <th className="text-right">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((item, key) => (
                          <tr key={key}>
                            <td>{item.name}</td>
                            <td>{item.role}</td>
                            <td>{item.telephone}</td>
                            <td>{format(new Date(Number(item.admission)), "dd/MM/yyyy")}</td>
                            <td className="text-center">{item.active ? `Ativo` : `Inativo`}</td>
                            <td style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end'
                            }}>
                              <Button
                                color={item.active ? "danger" : "success"}
                                className="animation-on-hover btn-icon btn-simple"
                                title=""
                                id="view_button_goker_status"
                                onClick={() => handleActiveUser(item.id, item.active)}
                              >
                                <i className={item.active ? "fas fa-lock" : "fas fa-unlock-alt"} />
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target="view_button_goker_status"
                                placement="top"
                              >
                                Habilitar Goker
                              </UncontrolledTooltip>
                              <Button
                                color="info"
                                className="animation-on-hover btn-icon btn-simple ml-2"
                                title=""
                                id="view_button_goker_view"
                                onClick={() => history.push(`/admin/perfil-goker/${item.id}`)}
                              >
                                <i className="fas fa-eye" />
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target="view_button_goker_view"
                                placement="top"
                              >
                                Visualizar
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
              </CardBody>
              {users.length >= 5 && (
                <CardFooter>
                  <Row className="pagination-container">
                    <Button
                      color="primary"
                      disabled={pageInfo.pageCount <= 1}
                      onClick={() => handleLoadMore()}
                    >
                      Carregar Mais
                  </Button>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Gokers;
