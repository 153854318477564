import React, { useState, useEffect, createRef } from "react";
import createPersistedState from 'use-persisted-state';
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import Cookies from "js-cookie";

// GraphQL
import { useQuery } from "@apollo/react-hooks";
import { ROLES } from "./admin.graphql";

// Components
import AdminNavbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

// Routes
import routes from "routes";

// Images
import logo from "assets/img/logo/logo@3x.png";

let ps;

const rolesState = createPersistedState('roles');

function Admin(props) {
  const history = useHistory();
  const mainPanel = createRef(null);

  //React States
  const [roles, setRoles] = rolesState([]);
  const [state, setState] = useState({
    backgroundColor: "primary",
    sidebarOpened:
      document.documentElement.className.indexOf("nav-open") !== -1
  });

  // GraphQL Queries
  const { data } = useQuery(ROLES);

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (data) {
      setRoles(data.roles.roles);
    }
  }, [data]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel, { suppressScrollX: true });

      let tables = document.querySelectorAll(".table-responsive");

      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    }
  }, []);

  useEffect(() => {
    if (history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");

        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainPanel.current.scrollTop = 0;
    }
  });

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setState({ sidebarOpened: !state.sidebarOpened });
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }

    return "Brand";
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar
          {...props}
          routes={routes}
          bgColor={state.backgroundColor}
          logo={{
            outterLink: "",
            text: "GO ON",
            imgSrc: logo
          }}
          toggleSidebar={toggleSidebar}
        />
        <div
          className="main-panel"
          ref={mainPanel}
          data={state.backgroundColor}
        >
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
            toggleSidebar={toggleSidebar}
            sidebarOpened={state.sidebarOpened}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {props.location.pathname.indexOf("maps") !== -1 ? null : (
            <Footer fluid />
          )}
        </div>
      </div>
    </>
  );
}

export default Admin;
